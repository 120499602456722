<template>
  <Header></Header>
  <div class="body-app">
    <transition name='custom-classes small-test-delete-me-after' enter-active-class="animate__animated animate__fadeIn animate__slow">
      <router-view />
    </transition>
  </div>
  <Footer></Footer>

</template>


<script>
  import Header from "@/components/header.vue";
  import Footer from "@/components/footer.vue";

  import utils from "@/utils/utils.js";

  export default {
    name: 'App',
    components: {
      Header,
      Footer,
    
    },

  }
</script>

<style lang="scss">
  @import "@/styles/general.scss";
</style>
