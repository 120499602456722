<template>
  <div class="header">
    <div class="container">
      <router-link :to="{name:'home'}"><img src="/img/logo.png"></router-link>
      <div class="menu">
        <p>
          <router-link to="/#bolsa" @click="scrollSection('bolsa')">Enplegu-poltsa</router-link>
        </p>
        <p><router-link to="/#euskotren" @click="scrollSection('euskotren')">Euskotren</router-link></p>
        <p><a href="https://adeccoagencia.iformalia.es/euskotren/registro">Registro</a></p>
        <p><a href="https://adeccoagencia.iformalia.es/publico/Portal/portalalumno/LoginUnicoAlumno.aspx">Acceso</a></p>
        <!-- <a href="www.google.es">
          <p>BASES CONVOCATORIA</p>
        </a> -->
      </div>
    </div>
  </div>
  <div class="hamburguesa">
    <div v-if="activada">
      <a href="/"><img src="/img/logo.png"></a>
    </div>
    <div v-else>
      <a href="/"><img src="/img/logo.png"></a>
      <div class="menu">
        <p><router-link to="/#bolsa" @click="scrollSection('bolsa')">Enplegu-poltsa</router-link></p>
        <p><router-link to="/#euskotren" @click="scrollSection('euskotren')">Euskotren</router-link></p>
        <p><a href="https://adeccoagencia.iformalia.es/euskotren/registro">Registro</a></p>
        <p><a href="https://adeccoagencia.iformalia.es/publico/Portal/portalalumno/LoginUnicoAlumno.aspx">Acceso</a></p>
        <!-- <a href="www.google.es">
          <p>BASES CONVOCATORIA</p>
        </a> -->
      </div>
    </div>

    <button @click="cambiarEstado()" v-if="!activada"><i class="bi bi-x"></i></button>
    <button @click="cambiarEstado()" v-else><i class="bi bi-list"></i></button>
  </div>
</template>

<script>
  export default {
    name: 'headerWorld',
    props: {
      msg: String
    },
    data() {
      return {
        activada: true,
      }
    },
    methods: {
      cambiarEstado() {
        this.activada = !this.activada;
      },
      scrollSection: function (params) {
        setTimeout(function () {
          document.getElementById(params).scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }, 200);

      }
    },
    watch: {
      "$route.path"() {
        this.activada = !this.activada;
        if (this.$route.hash) {
          let element = this.$route.hash.replace('#', '')
          setTimeout(function () {
            document.getElementById(element).scrollIntoView({
              behavior: "smooth",
              block: "start"
            });
          }, 200);
        }

      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
